<template>
  <div>

    <Nav @link="linkTo" :idx="5"></Nav>
    <div class="root_container">
      <div class="downloadLeft">
        <div class="downloadTitle">呼麦for Android</div>
        <div class="downloadDesc">通过GIS地图，即时通讯构建用户端app应用，实现附近情况即时知，任务情况随时达。</div>
        <div class="downloadBtn">
          <div class="btn" @mouseenter="mouseEnter" @mouseleave="mouseLeave" @click="download">下载客户端</div>
          <img class="code" :src="require('@/assets/img/apk-qrcode.png')" alt="">
          <div class="version">V1.0.0</div>
        </div>
      </div>
      <div class="downloadRight">
        <img class="chineseMap" :src="require('@/assets/img/prod4Img/img5.png')" alt="">
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import $ from 'jquery'

export default {
  name: 'download',
  components: {
    Nav,
    Footer
  },
  data() {
    return {

    }
  },
  created() { },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    linkTo(routerName) {
      this.$router.push(routerName)
    },
    mouseEnter() {
      $('.root_container .downloadLeft .downloadBtn .code').fadeIn('slow')
    },
    mouseLeave() {
      $('.root_container .downloadLeft .downloadBtn .code').fadeOut()
    },
    download() {
      console.log("下载")
      window.open('https://995aid.com/ramunion-files/apk/humai-latest.apk')
    }
  }
}

</script>

<style lang='less' scoped>
@media screen and (max-width: 767px) {

  // 移动端
  .root_container {
    padding: 0 15px;
    box-sizing: border-box;
    margin: 30px auto;
    .downloadLeft {
      width: 100%;
      .downloadTitle {
        font-weight: bold;
        font-size: 40px;
      }
      .downloadDesc {
        font-size: 18px;
        margin-top: 10px;
      }
      .downloadBtn {
        margin-top: 10px;
        width: 130px;
        text-align: center;
        .btn {
          width: 130px;
          height: 40px;
          line-height: 40px;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          background: #3F5EFF;
        }
        .code {
          display: none;
          width: 130px;
          height: 130px;
          margin-top: 10px;
        }

        .btn:hover+.code {
          display: block;
        }

        .version {
          font-size: 16px;
          color: #999;
          margin-top: 10px;
        }
      }
    }
    .downloadRight {
      flex: 1;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 992px) {

  // PC端
  .root_container {
    width: 1200px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 70px auto;
    display: flex;
    justify-content: space-between;

    .downloadLeft {
      width: 40%;

      .downloadTitle {
        font-weight: bold;
        font-size: 40px;
      }

      .downloadDesc {
        font-size: 18px;
        margin-top: 10px;
      }

      .downloadBtn {
        margin-top: 10px;
        width: 130px;
        text-align: center;

        .btn {
          width: 130px;
          height: 40px;
          line-height: 40px;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          background: #3F5EFF;
        }

        .code {
          display: none;
          width: 130px;
          height: 130px;
          margin-top: 10px;
        }

        .btn:hover+.code {
          display: block;
        }

        .version {
          font-size: 16px;
          color: #999;
          margin-top: 10px;
        }
      }
    }
    .downloadRight {
      flex: 1;
      text-align: center;
      margin-left: 20px;
      img {
        width: 100%;
      }
    }
  }
}</style>